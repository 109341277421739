<template>
  <v-container class="container--fluid">
    <div slot="widget-content">
      <div id="app">
        <v-app id="inspire">
          <component :is="component" :props-filter="filter" :props-model="model" :props-data-request="dataRequest" />
        </v-app>
      </div>
    </div>
  </v-container>
</template>

<script>
import AppListTable from '../../widget/AppListTable'
import ListTableBlank from './ListTableBlank'
import ListTableExcel from './ListTableExcel'
import ListTableClaim from './ListTableClaim'
import ListTableDoc from './ListTableDoc'
import ListTableFile from './ListTableFile'
import ListTableCertificate from './ListTableCertificate'
import ListTableCheckList from './ListTableCheckList'
import ListTablePreVehicle from './ListTablePreVehicle'

export default {
  name: 'ListTable',
  components: {
    ListTableCheckList,
    ListTableFile,
    AppListTable,
    ListTableBlank,
    ListTableExcel,
    ListTableClaim,
    ListTableDoc,
    ListTableCertificate,
    ListTablePreVehicle
  },
  data: () => ({
    model: '',
    dataRequest: '',
    filter: '',
    component: 'AppListTable'
  }),
  watch: {
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
    '$attrs.model': {
      handler(val) {
        this.name = 'Загрузка...'
        this.headers = []
        this.desserts = []
        this.model = val
        this.dataRequest = this.$attrs.dataRequest
        this.filter = { filter: this.$attrs.filter }
      },
      deep: true
    },
    '$attrs.component': {
      handler(val) {
        if (val != null) this.component = val
        else this.component = 'AppListTable'
      },
      deep: true
    }
  },
  created() {
    if (this.$attrs.component != null) this.component = this.$attrs.component
    else this.component = 'AppListTable'
    this.model = this.$attrs.model
    this.filter = { filter: this.$attrs.filter }
    this.dataRequest = this.$attrs.dataRequest
  }
}
</script>

<style>
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > td {
  font-size: 0.675rem;
  height: 48px;
}
</style>
