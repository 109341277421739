<template>
  <div>
    <pre-photo-dialog ref="prePhoto" />

    <document-edit-form ref="preCheckListForm" model="PreDocCheckList" />

    <v-overlay v-if="progress">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <app-list-table
      v-else
      :props-model="propsModel"
      :props-data-request="propsDataRequest"
      :props-filter="propsFilter"
    >
      <template v-slot:btn="{ item }">
        <v-tooltip top open-delay="500" :disabled="!$isMobile">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="takePhoto(item)"
              color="indigo darken-1"
              fab
              x-small
              dark
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-camera</v-icon>
            </v-btn>
          </template>
          <span>Сделать снимок</span>
        </v-tooltip>

        <v-tooltip top open-delay="500" :disabled="!$isMobile">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="editPrecheck(item)"
              color="teal"
              fab
              x-small
              dark
              v-bind="attrs"
              v-on="on"
              :loading="
                $refs.preCheckListForm.loading &&
                $refs.preCheckListForm.id === item.id
              "
            >
              <v-icon>mdi-file-document-edit-outline</v-icon>
            </v-btn>
          </template>
          <span>Редактировать пречек-лист</span>
        </v-tooltip>

        <v-tooltip top open-delay="500" :disabled="!$isMobile">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="showDialog(item)"
              color="purple"
              fab
              x-small
              dark
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-file-image</v-icon>
            </v-btn>
          </template>
          <span>Фотографии</span>
        </v-tooltip>

        <v-tooltip top open-delay="500" :disabled="!$isMobile">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="initCopyToClaim(item)"
              color="secondary"
              fab
              x-small
              dark
              v-bind="attrs"
              v-on="on"
              :loading="item.isCopying"
            >
              <v-icon>mdi-file-move-outline</v-icon>
            </v-btn>
          </template>
          <span>Перенести в заявку</span>
        </v-tooltip>
      </template>
    </app-list-table>

    <v-bottom-sheet v-model="sheet" persistent>
      <v-sheet class="text-center">
        <v-btn class="mt-6" text color="error" @click="sheet = false">
          Закрыть
        </v-btn>
        <app-list-table
          @select="claimSelectHandler"
          props-model="Claim"
          props-data-request="Claim"
          :props-select-mode="true"
        ></app-list-table>
      </v-sheet>
    </v-bottom-sheet>
  </div>
</template>

<script>
import { apiAttachment } from '@/api/api-attachment'
import { apiBlank } from '@/api/api-blank'
import AppListTable from '@/views/widget/AppListTable'
import PrePhotoDialog from '@/modules/PrePhoto/components/PrePhotoDialog.vue'
import DocumentEditForm from '@/views/components/Document/DocumentEditForm.vue'

export default {
  name: 'ListTablePreVehicle',
  components: {
    AppListTable,
    PrePhotoDialog,
    DocumentEditForm
  },
  props: {
    propsModel: String,
    propsDataRequest: String,
    propsFilter: Object
  },
  data() {
    return {
      uploading: false,
      progress: false,

      apiBlank: null,
      apiAttachment: null,

      sheet: false,
      item: null
    }
  },
  methods: {
    showDialog(item) {
      this.$refs.prePhoto.show(item.id, item.vin)
    },
    async editPrecheck(item) {
      await this.$refs.preCheckListForm.getDocument(item.id)
    },
    async takePhoto(item) {
      this.$refs.prePhoto.showCamera(item.id, item.vin)
    },
    initCopyToClaim(item) {
      this.item = item
      this.sheet = true
    },
    async claimSelectHandler(claim, item) {
      this.sheet = false

      try {
        this.item.isCopying = true
        await this.apiAttachment.copyToClaim(this.item.id, claim.id)
        this.$root.$emit('snackbar', `Чек-Лист перенесён в заявку ${claim.id}`)
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error.message)
      }

      this.progress = false
      this.item.isCopying = false
      await this.$forceUpdate()
      this.item = null
    },
  },
  created() {
    this.apiAttachment = new apiAttachment(
      this.propsModel,
      this.propsDataRequest
    )
  }
}
</script>
