<template>
  <v-avatar
    v-bind="$attrs"
    @click="getFullImage"
    style="cursor: pointer; position: relative"
  >
    <v-progress-circular
      v-if="loading"
      class="loader"
      :size="50"
      :width="3"
      color="primary"
      indeterminate
    ></v-progress-circular>
    <img :src="src" alt="thumbnail" />
  </v-avatar>
</template>

<script>
import { apiAttachment } from '@/api/api-attachment'

export default {
  name: 'AppAvatanPermission',
  props: ['id'],
  data() {
    return {
      src: null,
      loading: false
    }
  },
  created() {
    this.apiAttachment = new apiAttachment()
    this.getPreview(this.id)
  },
  methods: {
    async getPreview(id) {
      let thumb = await this.apiAttachment.getAttachmentThumb(id)
      thumb = thumb.data
      const blobFile = apiAttachment.b64toBlob(
        thumb.fileContents,
        thumb.contentType,
        thumb.fileDownloadName
      )

      this.src = URL.createObjectURL(blobFile)
    },
    async getFullImage() {
      this.$root.$emit(
        'open-fullscreen-image',
        new Promise(async (resolve) => {
          let thumb = await this.apiAttachment.getAttachmentDownload(this.id)
          thumb = thumb.data
          const blobFile = apiAttachment.b64toBlob(
            thumb.fileContents,
            thumb.contentType,
            thumb.fileDownloadName
          )
          const url = URL.createObjectURL(blobFile)

          const rotateLeftAction = {
            icon: 'mdi-rotate-left',
            handler: async (vm) => {
              rotateLeftAction.loading = true
              const { data } = await this.apiAttachment.rotateLeft(this.id)
              this.getPreview(this.id)
              if (!data) vm.rotations += 1
              else vm.rotations -= 1
              rotateLeftAction.loading = false
            },
            loading: false
          }

          const rotateRightAction = {
            icon: 'mdi-rotate-right',
            handler: async (vm) => {
              rotateRightAction.loading = true
              const { data } = await this.apiAttachment.rotateRight(this.id)
              this.getPreview(this.id)
              if (!data) vm.rotations -= 1
              else vm.rotations += 1
              rotateRightAction.loading = false
            },
            loading: false
          }

          resolve({
            src: url,
            meta: [{ name: 'Имя файла', value: thumb.fileDownloadName }],
            actions: [rotateLeftAction, rotateRightAction]
          })
        })
      )
    }
  }
}
</script>

<style scoped lang="scss">
.loader {
  z-index: 1;
  top: 0;
  left: 0;
  transform: translate(50%, 50%);
  position: absolute;
}
</style>
