<template>
  <div class="text-xs-center">
    <v-dialog v-model="dialog" width="600">
      <v-card flat>
        <v-card-title class="grey lighten-2" primary-title>
          <div>Фотография ({{ name }})</div>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="dialog = false"> Закрыть </v-btn>
        </v-card-title>

        <v-divider></v-divider>
        <img :src="src" alt="full_image" style="width: 100%" />
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      src: '',
      name: ''
    }
  },
  methods: {
    preview({ url, name }) {
      this.src = url
      this.name = name
      this.dialog = true
    }
  },
  created() {
    this.$root.$on('getFullPhoto', this.preview)
  },
  beforeDestroy() {
    this.$root.$off('getFullPhoto', this.preview)
  }
}
</script>
