<script>
import Swal from 'sweetalert2'
import { apiBlank } from '@/api/api-blank'
import { apiDirectory } from '@/api/api-directory'
import AppFormWidget from '@/views/widget/AppFormWidget'

export default {
  components: {
    AppFormWidget
  },
  props: {
    model: {
      type: String
    }
  },
  data() {
    return {
      dialogController: {
        dialog: false,
        dataValue: null
      },
      apiBlank: null,
      apiDirectory: null,
      modelData: null,
      snackbarText: '',
      snackbar: false,
      loading: false,
      documentDescription: null
    }
  },
  methods: {
    async getDocument(preVehicleId) {
      this.id = preVehicleId
      this.loading = true

      const { data } = await this.apiDirectory.getAll(1, 1, null, {
        filter: [
          {
            field: 'PreVehicleId',
            operator: 0,
            value: preVehicleId
          }
        ]
      })

      this.loading = false

      if (!data.total) {
        Swal.fire('Ошибка', 'Документ не найден', 'error')
        return
      }

      this.dialogController.dialog = true
      this.dialogController.dataValue = data.data[0]
    },
    async save(value) {
      await this.update(value)
    },
    async initialize() {
      this.apiBlank = new apiBlank(this.model, this.model)
      this.apiDirectory = new apiDirectory(this.model, this.model)
      let model
      this.editedItem = []
      try {
        model = await this.apiDirectory.getModel()
        this.modelData = model.data.properties
        this.apiDirectory.setModel(this.modelData)

        this.apiBlank.docCheckListsDescription().then((response) => {
          this.documentDescription = response.data.data
        })
      } catch (e) {
        console.warn(e)
      }
    },
    mutationCheckListsDescription(listForm) {
      if (this.documentDescription == null) {
        Swal.fire(
          'Ошибка интерфейса',
          'Данные не успели загрузиться docCheckListsDescription откройте редактирования заново',
          'error'
        )
      }
      this.documentDescription.forEach((item) => {
        const index = listForm
          .map(function (e) {
            return e.key
          })
          .indexOf(item.columnName)
        if (index !== -1) {
          if (item.type === 1) {
            listForm[index].type = 'enum'
            listForm[index].options = this.parseStringToArray(item.values)
          }
          if (item.groupName != null) listForm[index].groupLine = item.groupName
          if (item.name != null) listForm[index].groupGrid = item.name
          if (item.postfix != null) listForm[index].postfix = item.postfix
          if (item.prefix != null) listForm[index].prefix = item.prefix
          if (item.sort != null) listForm[index].sort = item.sort
          if (item.enabled != null)
            listForm[index].disabled = item.enabled === 0
          listForm[index].text = ''
        }
      })
      listForm.forEach((list) => {
        !list.sort && (list.sort = 0)
      })
      listForm.sort(function (a, b) {
        return a.sort - b.sort
      })
      return listForm
    },
    async update(edited) {
      this.loading = true
      try {
        await this.apiDirectory.update(edited)
        this.snackbarText = 'Запись успешно обновлена'
        this.snackbar = true
        this.close()
        await this.initialize()
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    close() {
      this.dialogController.dialog = false
    }
  },
  async created() {
    this.initialize()
  }
}
</script>

<template>
  <div>
    <app-form-widget
      v-model="dialogController"
      :model-value="modelData"
      :props-mutation-data="[mutationCheckListsDescription]"
      :loading="loading"
      @save="save"
    >
    </app-form-widget>
    <v-snackbar
      color="deep-purple accent-4"
      :top="true"
      :right="true"
      v-model="snackbar"
      :timeout="2000"
    >
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar = false"> Закрыть </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
