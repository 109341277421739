<template>
  <div>
    <v-dialog v-model="dialogDelete" persistent max-width="900">
      <v-card>
        <v-card-title class="text-h5">{{ textAlert }}</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" depressed @click="close">Нет</v-btn>
          <v-btn color="success" depressed @click="confirm">Да</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <app-list-table
      ref="listTable"
      :props-model="propsModel"
      :props-data-request="propsDataRequest"
      :props-filter="propsFilter"
      :props-filter-table="FilterTable"
      v-on:edited-item-event="editedItemEvent"
    >
      <template v-slot:form="{ item, edit }">
        <v-progress-circular v-if="load" indeterminate color="primary"></v-progress-circular>
        <template v-else>
          <template v-if="editedItem">
            <v-btn style="margin-left: 10px; color: white" small depressed color="indigo" @click="commitDoc(item)"> Записи документа </v-btn>
            <v-btn style="margin-left: 10px" small v-if="editedItem.headerId == null" depressed color="primary" @click="commit(editedItem)"
              >Не проведено
            </v-btn>
            <v-btn style="margin-left: 10px" small v-else depressed color="success" @click="rollback(editedItem)">Проведено </v-btn>
          </template>
        </template>
        <v-btn style="margin-left: 10px" small depressed color="blue" @click="saveAndSpend(item, edit)">Сохранить и провести</v-btn>
      </template>
      <template v-slot:btn="{ item }">
        <v-progress-circular v-if="load" :size="15" :width="1" indeterminate color="primary"></v-progress-circular>
        <template v-else>
          <v-btn style="margin-left: 10px; color: white" small color="success" @click="commitDoc(item)"> Записи документа </v-btn>
          <v-btn style="margin-left: 10px" small v-if="item.headerId == null" depressed color="primary" @click="commit(item)">Не проведено </v-btn>
          <v-btn style="margin-left: 10px" small v-else depressed color="success" @click="rollback(item)">Проведено </v-btn>
        </template>
      </template>
    </app-list-table>
  </div>
</template>

<script>
import { TypeFilter } from '../../../api/api-directory'
import AppListTable from '../../widget/AppListTable'
import { apiBlank } from '../../../api/api-blank'
import Swal from 'sweetalert2'

const Toast = Swal.mixin({
  toast: true,
  width: '32rem',
  position: 'top-end',
  showConfirmButton: true,
  timer: 5000,
  timerProgressBar: true,
  didOpen: toast => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  }
})

export default {
  name: 'ListTableDoc',
  components: {
    AppListTable
  },
  props: {
    propsModel: String,
    propsDataRequest: String,
    propsFilter: Object
  },
  data: () => ({
    FilterTable: [
      { name: 'Все', id: -1, type: TypeFilter.CHECK, default: true, filter: [] },
      { name: 'Не проведенные', type: TypeFilter.CHECK, filter: [{ field: 'HeaderId', operator: 17, value: null }] },
      { name: 'Проведённые', type: TypeFilter.CHECK, filter: [{ field: 'HeaderId', operator: 2, value: 0 }] },
      { name: 'Фильтр по дате', type: TypeFilter.DATE_TIME }
    ],
    model: '',
    textAlert: '',
    dialogDelete: false,
    apiBlank: '',
    type: null,
    item: null,
    load: false,
    editedItem: null
  }),
  created() {
    this.apiBlank = new apiBlank(this.propsModel)
  },
  methods: {
    async saveAndSpend(item, edit = -1) {
      this.item = item
      if (edit === -1) delete this.item['Id']
      try {
        await this.apiBlank.CreateCommitInspections(this.item)
        this.$refs.listTable.close()
        await this.$refs.listTable.initialize(this.propsModel, this.propsDataRequest)
        this.snackbarText = 'Запись успешно сохранена'
        this.snackbar = true
      } catch (e) {}
    },
    editedItemEvent(item) {
      this.editedItem = item
    },
    commit(item) {
      this.dialogDelete = true
      this.textAlert = 'Вы уверены, что хотите провести бланк?'
      this.type = 'commit'
      this.item = item
    },
    rollback(item) {
      this.dialogDelete = true
      this.textAlert = 'Вы уверены, что хотите откатить бланк?'
      this.type = 'rollback'
      this.item = item
    },
    close() {
      this.dialogDelete = false
    },
    confirm() {
      this.dialogDelete = false
      this.sendServer()
    },
    async validate() {
      switch(this.propsModel) {
      case 'DocInspections':
        let messages = ''
        const { data } = await this.apiBlank.validate(this.item.id)

        data?.forEach(message => {
          messages += message + ';<br>'
        })

        if (messages) {
          Toast.fire({
            icon: 'warning',
            html: messages
          })
        }
        break
      default:
        break
      }
    },
    async sendServer() {
      let request
      try {
        this.load = true
        switch (this.type) {
        case 'commit':
          await this.validate()
          request = await this.apiBlank.commit(this.item.id)
          break
        case 'rollback':
          request = await this.apiBlank.rollback(this.item.id)
          break
        default:
          break
        }
        await this.$refs.listTable.initialize(this.propsModel, this.propsDataRequest)
        await this.$refs.listTable.close()
        this.$forceUpdate()
      } catch (e) {
        if (typeof e.response.data === 'object') {
          Swal.fire(e.response.data.title, e.response.data.detail, 'error')
        } else {
          Swal.fire('Не обработанная ошибка', e.response.data, 'error')
        }
      }
      this.load = false
      return request
    },
    commitDoc(item) {
      this.$router.push('/document/records/' + item.id)
      // this.load = true;
      // this.apiDocument.document(item.id)
      // this.sendServerDoc(item);
    },
    async sendServerDoc(item) {
      const request = await this.apiDocument.document(item.id)
      //
      // let blob = new Blob([request.data], { type: request.headers['content-type'] }),
      const url = window.URL.createObjectURL(request.data)
      window.open(url, '_blank')
      this.load = false
    }
  }
}
</script>

<style scoped></style>
