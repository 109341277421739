<template>
  <div>
    <app-download-to-type
      ref="downloadImageWidget"
      :uploading="uploading"
      :downloading-all="downloadingAll"
      @copy-to-claim="copyToClaim"
      @confirm-all="confirmDownloadAllImages"
      @confirm="confirmDownloadImage"
      :data-list="photoList"
      @dataEvent="downloadImageData"
    />
    <app-download-to-type
      ref="downloadFileWidget"
      :uploading="uploading"
      :downloading-all="downloadingAll"
      @confirm-all="confirmDownloadAllFiles"
      @confirm="confirmDownloadImage"
      :dataList="fileList"
      @dataEvent="downloadFileData"
    />
    <v-dialog v-model="dialogDelete" persistent max-width="900">
      <v-card>
        <v-card-title class="text-h5">Скачать файла</v-card-title>
        <v-card-text>
          <v-select :items="typeItemsDownload" v-model="typeFile" label="Тип файла" item-text="name" item-value="id"></v-select>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" depressed @click="close">Отмена</v-btn>
          <v-btn color="success" depressed @click="confirm">Скачать</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogFind" persistent max-width="900">
      <v-card>
        <v-card-title class="text-h5">Просмотр файла</v-card-title>
        <v-card-text>
          <v-select :items="getDialogFindType()" v-model="typeFile" label="Тип файла" item-text="name" item-value="id"></v-select>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" depressed @click="close">Отмена</v-btn>
          <v-btn color="success" depressed @click="dialogFindConfirm">Просмотр</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <app-list-table
      ref="listTable"
      :props-model="propsModel"
      :props-data-request="propsDataRequest"
      :props-filter="propsFilter"
      :props-filter-table="filterArray"
      :eventActionLoading="eventActionLoading"
      v-on:all-item-event="allItemEvent"
      v-on:edited-item-event="editedItemEvent"
      @download-by-type="downloadByType"
    >
      <template v-slot:form>
        <v-btn small depressed color="indigo" @click="commitModal(item)" dark :loading="item && load == item.id">
          <v-icon>mdi-file-download-outline</v-icon>
        </v-btn>
      </template>
      <template v-slot:btn="{ item }">
        <v-tooltip top open-delay="500" :disabled="!$isMobile">
          <template v-slot:activator="{ on, attrs }">
            <v-btn x-small fab color="indigo" @click="commitModal(item)" dark :loading="load == item.id" v-bind="attrs" v-on="on">
              <v-icon color="white">mdi-file-download-outline</v-icon>
            </v-btn>
          </template>
          <span>Скачать</span>
        </v-tooltip>

        <v-tooltip top open-delay="500" :disabled="!$isMobile">
          <template v-slot:activator="{ on, attrs }">
            <v-btn x-small fab color="cyan" @click="openDialogFind(item)" dark :loading="openingFile == item.id" v-bind="attrs" v-on="on">
              <v-icon color="white">mdi-search-web</v-icon>
            </v-btn>
          </template>
          <span>Просмотр</span>
        </v-tooltip>

        <v-tooltip top open-delay="500" :disabled="!$isMobile">
          <template v-slot:activator="{ on, attrs }">
            <v-btn x-small fab color="deep-purple" @click="downloadImage(item)" v-bind="attrs" v-on="on">
              <v-icon color="white">mdi-file-image</v-icon>
            </v-btn>
          </template>
          <span>Фотографии</span>
        </v-tooltip>

        <v-tooltip top open-delay="500" :disabled="!$isMobile">
          <template v-slot:activator="{ on, attrs }">
            <v-btn x-small fab color="pink" @click="downloadFile(item)" v-bind="attrs" v-on="on">
              <v-icon color="white">mdi-file-document</v-icon>
            </v-btn>
          </template>
          <span>Документы</span>
        </v-tooltip>
      </template>
    </app-list-table>
  </div>
</template>

<script>
import Swal from 'sweetalert2'
import AppListTable from '@/views/widget/AppListTable'
import { apiDocument } from '@/api/api-document'
import { apiDirectory, TypeFilter } from '@/api/api-directory'
import { apiAttachment } from '@/api/api-attachment'
import AppDownloadToType from '@/views/widget/AppDownloadToType'

const Toast = Swal.mixin({
  toast: true,
  width: '32rem',
  position: 'top-end',
  showConfirmButton: true,
  timer: 5000,
  timerProgressBar: true,
  didOpen: toast => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  }
})

export default {
  name: 'ListTableClaim',
  components: {
    AppDownloadToType,
    AppListTable
  },
  props: {
    propsModel: String,
    propsDataRequest: String,
    propsFilter: Object
  },
  data: () => ({
    downloadingAll: false,
    uploading: false,
    openingFile: false,
    photoList: [],
    fileList: [],
    model: '',
    filterArray: '',
    textAlert: '',
    dialogDelete: false,
    dialogFind: false,
    apiDocument: '',
    apiStatus: '',
    type: null,
    typeFile: -1,
    item: null,
    load: false,
    eventActionLoading: false,
    editedItem: null,
    filterFileAttachment: [],
    typeItemsDownload: [{ name: 'Скачать все (Ошибка это стандартные поля)', id: -1 }],
    typeItemsUpload: [{ name: 'Скачать все (Ошибка это стандартные поля)', id: -1 }],
    sheet: false
  }),
  created() {
    this.apiDocument = new apiDocument()
    this.apiDirectory = new apiDirectory('BaseFile', this.propsDataRequest)
    this.apiAttachment = new apiAttachment()
    this.apiStatus = new apiDirectory('ClaimStatus', 'ClaimStatus')
    this.init()
  },
  methods: {
    getDialogFindType() {
      return this.typeItemsDownload.filter(function(e) {
        return e.id !== -1
      })
    },
    async copyToClaim(value) {
      try {
        const data = await this.apiAttachment.copyPhotoToClaim(...value)
        await this.downloadImageData(value[1])
        this.snackbarText = 'Изображение добавлено'
        this.snackbar = true
      } catch (e) {}
    },
    async downloadByType({ ids, documentType }) {
      this.eventActionLoading = true
      try {
        const res = await this.apiDocument.downloadDocumentsByType(ids, documentType?.id || documentType)
        this.downloadBlob(+new Date() + '.zip', res.data)
      } catch {}
      this.eventActionLoading = false
    },
    async confirmDownloadImage(/** @type {{id: string, type: string, file:File, sectionType: number}} */ value) {
      this.uploading = true
      try {
        let data = null
        if (!Array.isArray(value.files) || value.files.length < 2) {
          data = await this.apiAttachment.createAttachment({
            File: !Array.isArray(value.files) ? value.files : value.files[0],
            AttachmentType: value.type,
            SectionType: value.sectionType,
            ClaimId: value.id
          })
        } else {
          data = await this.apiAttachment.createAttachments({
            Files: value.files,
            AttachmentType: value.type || 4,
            SectionType: value.sectionType,
            ClaimId: value.id
          })
        }
        setTimeout(() => {
          if (value.sectionType == 0) {
            this.downloadImageData(value.id)
            this.snackbarText = 'Изображение добавлено'
          } else {
            this.downloadFileData(value.id)
            this.snackbarText = 'Документ добавлен'
          }
          this.snackbar = true
        }, 600)
      } catch (e) {}
      this.uploading = false
    },
    async confirmDownloadAllFiles(claimId) {
      this.downloadingAll = true
      try {
        const data = await this.apiAttachment.getDocumentsDownload(claimId)
        const filename = this.parseStringToArray(data.headers['content-disposition'])['filename']
        this.downloadBlob(filename, data.data)
        this.snackbarText = 'Документы загружены'
        this.snackbar = true
      } catch (e) {}
      this.downloadingAll = false
    },
    async confirmDownloadAllImages(claimId) {
      this.downloadingAll = true
      try {
        const data = await this.apiAttachment.getPhotosDownload(claimId)
        const filename = this.parseStringToArray(data.headers['content-disposition'])['filename']
        this.downloadBlob(filename, data.data)
        this.snackbarText = 'Фотографии загружены'
        this.snackbar = true
      } catch (e) {}
      this.downloadingAll = false
    },
    async downloadImageData(id) {
      const fileList = await this.apiAttachment.getAttachmentList(id, 0)
      console.log('downloadImageData', fileList.data.data)
      this.photoList = fileList.data.data
    },
    async downloadImage(item) {
      this.$refs.downloadImageWidget.showDownload({
        id: item.id,
        filter: this.filterFileAttachment,
        name: 'Фотографии',
        sectionType: 0
      })
      await this.downloadImageData(item.id)
      this.appDownloadToType && (this.appDownloadToType.deleting = null)
    },
    async downloadFileData(id) {
      const fileList = await this.apiAttachment.getAttachmentList(id, 1)
      this.fileList = fileList.data.data
    },
    async downloadFile(item) {
      this.$refs.downloadFileWidget.showDownload({
        id: item.id,
        filter: this.filterFileAttachment,
        name: 'Документы',
        sectionType: 1
      })
      this.downloadFileData(item.id)
    },
    downloadBlob(filename, data) {
      const url = window.URL.createObjectURL(new Blob([data]))
      const a = document.createElement('a')
      a.href = url
      a.setAttribute('download', filename)
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
    },
    openDialogFind(item) {
      this.item = item
      this.dialogFind = true
    },
    async init() {
      this.filterArray = [{ name: 'Все', filter: [], type: TypeFilter.CHECK, default: true }]
      const model = await this.apiDirectory.getFileType(this.propsDataRequest)
      const filterFileAttachment = await this.apiAttachment.getModel(this.propsDataRequest)
      const filterValue = filterFileAttachment.data.properties.AttachmentType.attributes.Values
      this.filterFileAttachment = apiAttachment.parseStringToArray(filterValue)
      const status = await this.apiStatus.getAll(1, 1000, {})
      if (typeof model.data.upload !== 'object' && typeof model.data.download !== 'object')
        return Swal.fire(
          'Ошибка',
          `В документе нет объектов со значением
                         ${typeof model.data.download !== 'object' ? 'Отсутствует файл загрузки (download)' : ''}
                         ${typeof model.data.upload !== 'object' ? 'Отсутствует файл отправки (upload)' : ''}`,
          'error'
        )

      this.typeItemsDownload = [{ name: 'Скачать все', id: -1, type: TypeFilter.CHECK }]
      this.typeItemsUpload = [{ name: 'Скачать все', id: -1, type: TypeFilter.CHECK }]
      for (const [key, value] of Object.entries(model.data.download)) {
        this.typeItemsDownload.push({
          name: value,
          id: key
        })
      }
      for (const [key, value] of Object.entries(model.data.upload)) {
        this.typeItemsUpload.push({
          name: value,
          id: key
        })
      }

      status.data.data.forEach(value => {
        this.filterArray.push({
          name: value.name,
          filter: [{ field: 'StatusId', operator: 0, value: value.id }],
          type: TypeFilter.CHECK,
          default: false
        })
      })
      this.filterArray.push({
        name: 'Фильтр по дате',
        type: TypeFilter.DATE_TIME
      })
      this.$refs.listTable.addOptionsActions(10, 'Скачать все')
      this.$refs.listTable.addOptionsActions(11, 'Скачать по типу')
    },
    parseStringToArray(value) {
      const newObject = {}
      const arrayValue = value.split(';')
      arrayValue.forEach(item => {
        const objectItem = item.split('=')
        newObject[objectItem[0].trim()] = objectItem[1]
      })
      return newObject
    },
    editedItemEvent(item) {
      this.editedItem = item
    },
    checkDocuments(claimIds) {
      let messages = ''
      const promises = []

      claimIds.forEach(id => {
        promises.push(this.apiDocument.validate(id))
      })

      Promise.all(promises).then(results => {
        results.forEach(response => {
          response.data?.forEach(message => {
            messages += message + ';<br>'
          })
        })

        if (messages) {
          Toast.fire({
            icon: 'warning',
            html: messages
          })
        }
      })
    },
    async allItemEvent(item) {
      if (item.action === 10) {
        let idList = ''

        item.select.forEach(valueSelect => {
          idList += valueSelect.id + ','
        })

        idList = idList.substring(0, idList.length - 1)
        this.checkDocuments(idList.split(','))
        const request = await this.apiDocument.documentTypeMany(idList)
        if (request != null) {
          // let blob = new Blob([request.data], { type: request.headers['content-type'] }),
          const url = window.URL.createObjectURL(request.data)
          const a = document.createElement('a')
          const contentDisposition = require('content-disposition')
          if (request.headers['content-disposition']) {
            var disposition = contentDisposition.parse(request.headers['content-disposition'])
            document.body.appendChild(a)
            a.style = 'display: none'
            a.href = url
            a.download = disposition.parameters.filename
            a.click()
          } else {
            window.open(url, '_blank')
          }
        }
      }
      // this.editedItem = item;
    },
    // eslint-disable-next-line no-unused-vars
    commitModal(item) {
      this.item = item
      this.dialogDelete = true
    },
    close() {
      this.item = null
      this.dialogDelete = false
      this.dialogFind = false
    },
    dialogFindConfirm() {
      this.dialogFindOpen(this.item)
      this.openingFile = this.item.id
      this.item = null
      this.dialogFind = false
    },
    confirm() {
      this.commit(this.item)
      this.item = null
      this.dialogDelete = false
    },
    commit(item, type) {
      this.load = item.id
      this.sendServer(item, type)
    },
    IsJsonString(str) {
      try {
        JSON.parse(str)
      } catch (e) {
        return false
      }
      return true
    },
    async sendServer(item) {
      this.checkDocuments([item.id])
      let request = null
      try {
        if (this.typeFile === -1) request = await this.apiDocument.document(item.id)
        else request = await this.apiDocument.documentType(item.id, this.typeFile)
      } catch (e) {
        const json = await e.response.data.text()
        if (this.IsJsonString(json)) {
          Swal.fire(JSON.parse(json).title, JSON.parse(json).detail, 'error')
        } else {
          Swal.fire('Не обработанная ошибка', json, 'error')
        }
      }
      if (request != null) {
        const url = window.URL.createObjectURL(request.data)
        const a = document.createElement('a')
        const contentDisposition = require('content-disposition')
        if (request.headers['content-disposition']) {
          var disposition = contentDisposition.parse(request.headers['content-disposition'])
          document.body.appendChild(a)
          a.style = 'display: none'
          a.href = url
          a.download = disposition.parameters.filename
          a.click()
        } else {
          window.open(url, '_blank')
        }
      }
      this.load = false
    },
    async dialogFindOpen(item) {
      let request = null
      try {
        if (this.typeFile !== -1) request = await this.apiDocument.documentTypeOpen(item.id, this.typeFile)
      } catch (e) {
        const json = await e.response.data.text()
        if (this.IsJsonString(json)) {
          Swal.fire(JSON.parse(json).title, JSON.parse(json).detail, 'error')
        } else {
          Swal.fire('Не обработанная ошибка', json, 'error')
        }
      }
      if (request != null) {
        // let blob = new Blob([request.data], { type: request.headers['content-type'] }),
        window.open(window.URL.createObjectURL(request.data))
        // let pdfWindow = window.open("")
        // pdfWindow.document.write();
        // let url = window.URL.createObjectURL(request.data)
        // let a = document.createElement("a");
        // let contentDisposition = require('content-disposition')
        // if (request.headers['content-disposition']) {
        //     var disposition = contentDisposition.parse(request.headers['content-disposition']);
        //     document.body.appendChild(a);
        //     a.style = "display: none";
        //     a.href = url;
        //     a.download = disposition.parameters.filename;
        //     a.click();
        // } else {
        //     Object.assign(document.createElement('a'), {
        //         target: '_blank',
        //         href: url,
        //     }).click();
        //     // window.open(url, "_blank").focus();
        // }
      }
      this.openingFile = false
      this.load = false
    }
  }
}
</script>

<style scoped></style>
