import http from './http-common'
import store from '../store'
import GeneralAPI from '.'

/**
 * @param {apiBlank} apiBlank
 */
export class apiBlank extends GeneralAPI {
  _model = ''
  _basicAuth = ''
  constructor(model) {
    super()
    this._model = model
    this._basicAuth = `Bearer ${store.getters.token}`
  }

  /**
   * Провести записей
   */
  commit(id) {
    return http
      .get(`/${this._model}/commit/${id}`, {
        headers: {
          Authorization: this._basicAuth
        }
      })
      .catch((error) => {
        this.errorCatch(error)
        return Promise.reject(error)
      })
  }
  /**
   * Откатить записей
   */
  rollback(id) {
    return http
      .get(`/${this._model}/rollback/${id}`, {
        headers: {
          Authorization: this._basicAuth
        }
      })
      .catch((error) => {
        this.errorCatch(error)
        return Promise.reject(error)
      })
  }
  /**
   * Проверка корректности приложенных к документу данных
   * @param id id документа
   */
  validate(id) {
    return http
      .get(`/${this._model}/check/${id}`, {
        headers: {
          Authorization: this._basicAuth
        }
      })
      .catch((error) => {
        this.errorCatch(error)
        return Promise.reject(error)
      })
  }
  /**
   * Получить все записи
   */
  docCheckListsDescription() {
    return http
      .get(`/DocCheckListsDescription/all`, {
        headers: {
          Authorization: this._basicAuth
        }
      })
      .catch((error) => {
        this.errorCatch(error)
        return Promise.reject(error)
      })
  }
  /**
   * Получить модель данных
   */
  SimilarDocCheckListModelModel() {
    return http
      .get(`/utils/Metadata/SimilarDocCheckListModel`, {
        headers: {
          Authorization: this._basicAuth
        }
      })
      .catch((error) => {
        this.errorCatch(error)
        return Promise.reject(error)
      })
  }
  /**
   * Получить модель данных
   */
  SimilarDocCheckListModel(data) {
    return http
      .post(`/DocCheckList/similar`, data, {
        headers: {
          Authorization: this._basicAuth
        }
      })
      .catch((error) => {
        this.errorCatch(error)
        return Promise.reject(error)
      })
  }
  /**
   * Получить модель данных
   */
  CreateCommitCheckList(data) {
    return http
      .put(`/DocCheckList/createcommit`, data, {
        headers: {
          Authorization: this._basicAuth
        }
      })
      .catch((error) => {
        this.errorCatch(error)
        return Promise.reject(error)
      })
  }
  /**
   * Получить модель данных
   */
  CreateCommitInspections(data) {
    return http
      .put(`/DocInspections/createcommit`, data, {
        headers: {
          Authorization: this._basicAuth
        }
      })
      .catch((error) => {
        this.errorCatch(error)
        return Promise.reject(error)
      })
  }
  /**
   * скачивания протокола испытаний
   */
  GetDownloadDocument(data) {
    return http
      .get(`/Document/${data.claimId}/8`, {
        headers: {
          Authorization: this._basicAuth
        },
        responseType: 'blob'
      })
      .catch((error) => {
        this.errorCatch(error)
        return Promise.reject(error)
      })
  }
}

var err = null
