<template>
  <div>
    <v-dialog v-model="dialog" persistent max-width="290">
      <v-card>
        <v-card-title class="text-h5"> Уведомления </v-card-title>
        <v-card-text>{{ dialogText }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="dialog = false"> Закрыть </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="upload.dialog" persistent max-width="900">
      <v-card>
        <v-card-title class="text-h5">Загрузить файла</v-card-title>
        <v-card-actions>
          <v-btn color="primary" depressed @click="closeDialog">Отмена</v-btn>
          <v-btn color="success" depressed @click="confirmDialog">Загрузить</v-btn>
        </v-card-actions>
        <v-card-text>
          <v-container>
            <v-autocomplete
              v-model="upload.type"
              :items="typeItemsUpload"
              item-text="name"
              item-value="id"
              label="Сотрудник"
              outlined
              return-object
            ></v-autocomplete>
            <v-file-input accept=".cer" v-model="upload.file" label="Сертификат" outlined></v-file-input>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <app-list-table
      ref="listTable"
      :props-model="propsModel"
      :props-data-request="propsDataRequest"
      :props-filter="propsFilter"
      v-on:edited-item-event="editedItemEvent"
    >
      <template v-slot:form>
        <v-progress-circular v-if="load" :size="15" :width="1" indeterminate color="primary"></v-progress-circular>
        <template v-else>
          <v-btn
            style="margin-left: 10px"
            small
            depressed
            color="indigo"
            @click="
              () => {
                commit(editedItem, 1)
              }
            "
          >
            <v-icon color="white">mdi-file-download-outline</v-icon>
          </v-btn>
        </template>
      </template>
      <template v-slot:header>
        <v-btn style="margin-left: 10px" color="warning" dark class="mb-2" v-on:click="showDialog"> Загрузить </v-btn>
      </template>
    </app-list-table>
  </div>
</template>

<script>
import AppListTable from '../../widget/AppListTable'
import { apiDocument } from '../../../api/api-document'
import Swal from 'sweetalert2'
import http from '../../../api/http-common'
import store from '../../../store'
import { apiDirectory } from '../../../api/api-directory'

export default {
  name: 'ListTableCertificate',
  components: {
    AppListTable
  },
  props: {
    propsModel: String,
    propsDataRequest: String,
    propsFilter: Object
  },
  data: () => ({
    model: '',
    textAlert: '',
    dialog: false,
    dialogText: '',
    apiDocument: '',
    typeFile: -1,
    item: null,
    load: false,
    editedItem: null,
    typeItemsUpload: [{ name: 'Скачать все (Ошибка это стандартные поля)', id: -1 }],
    upload: {
      dialog: false,
      type: 0,
      file: null
    }
  }),
  created() {
    this.apiDocument = new apiDocument()
    this.apiDirectory = new apiDirectory(this.propsModel, this.propsDataRequest)
    this.init()
  },
  methods: {
    async init() {
      const model = await this.apiDirectory.getCertificateType()
      if (model.data.length > 0) return Swal.fire('Ошибка', `Количества пользователей меньше нуля`, 'error')

      this.typeItemsUpload = []
      for (const value of model.data.data) {
        this.typeItemsUpload.push({
          name: value.name,
          id: value.id
        })
      }

    },
    parseStringToArray(value) {
      const newObject = {}
      const arrayValue = value.split(';')
      arrayValue.forEach((item) => {
        const objectItem = item.split('=')
        newObject[objectItem[0]] = objectItem[1]
      })
      return newObject
    },
    showDialog() {
      this.upload.dialog = true
      this.upload.type = null
      this.upload.file = null
    },
    closeDialog() {
      this.upload.dialog = false
    },
    confirmDialog() {
      this.closeDialog()
      this.submitFiles(this.upload.file, this.upload.type.id)
    },
    editedItemEvent(item) {
      this.editedItem = item
    },
    commit(item, type) {
      this.load = true
      // this.apiDocument.document(item.id)
      this.sendServer(item, type)
    },
    IsJsonString(str) {
      try {
        JSON.parse(str)
      } catch (e) {
        return false
      }
      return true
    },
    async sendServer(item) {
      let request = null
      try {
        // if (this.typeFile === -1)
        request = await this.apiDocument.UploadFile(item.id)
        // else
        //     request = await this.apiDocument.documentType(item.id, item.fileType);
      } catch (e) {
        const json = await e.response.data.text()
        if (this.IsJsonString(json)) {
          Swal.fire(JSON.parse(json).title, JSON.parse(json).detail, 'error')
        } else {
          Swal.fire('Не обработанная ошибка', json, 'error')
        }
      }
      if (request != null) {
        var link = document.createElement('a')
        document.body.appendChild(link)
        link.setAttribute('type', 'hidden')
        link.href = 'data:' + request.data.fileContents + ';base64,' + request.data.fileContents
        link.download = request.data.fileDownloadName
        link.click()
        document.body.removeChild(link)
      }
      this.load = false
    },
    submitFiles(file, id) {
      const formData = new FormData()
      formData.append('File', file, file.name)
      formData.append('FileType', 28)
      formData.append('StaffId', id)
      http
        .post('/UploadFile', formData, {
          headers: {
            Authorization: `Bearer ${store.getters.token}`
          }
        })
        .then((response) => {
          this.dialog = true
          this.dialogText = 'Загрузка прошла успешно'
          this.$refs.listTable.initialize(this.propsModel, this.propsDataRequest, this.propsFilter)
        })
        .catch((error) => {
          let errors = 'Неизвестная ошибка'
          if (error.response.data.errors != null)
            Object.keys(error.response.data.errors).forEach((key) => {
              error.response.data.errors[key].forEach((item) => {
                if (errors === 'Неизвестная ошибка') errors = ''
                errors += `[${key}] - ` + item + '\n'
              })
            })
          if (error.response.data.title != null) {
            errors = error.response.data.title
          }
          this.dialogText = errors
          this.dialog = true
        })
    }
  }
}
</script>

<style scoped></style>
