<script>
export default {
  props: {
    icon: String,
    text: {
      type: String,
      required: true
    },
    loading: Boolean
  }
}
</script>

<template>
  <v-col class="content-placeholder">
    <v-progress-circular
      v-if="loading"
      :size="36"
      :width="3"
      indeterminate
      color="grey lighten-1"
      class="content-placeholder__loading"
    />
    <v-icon v-else size="90" class="spin" color="grey lighten-1">{{
      icon
    }}</v-icon>
    <h1>{{ text }}</h1>
  </v-col>
</template>

<style lang="scss" scoped>
.content-placeholder {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  flex-direction: column;

  h1 {
    font-size: 20px;
    font-weight: 600;
    opacity: 0.5;
    user-select: none;
  }

  &__loading {
    margin-bottom: 20px;
  }
}
</style>
