<template>
  <v-dialog scrollable v-model="content.dialog" max-width="900px">
    <v-card :loading="loading">
      <v-card-title>
        <span class="text-h5">{{ formTitle }}</span>
      </v-card-title>
      <v-card-actions>
        <v-btn small color="primary" depressed @click="close"> Отмена </v-btn>
        <v-btn small color="success" depressed @click="save"> Сохранить </v-btn>
        <slot name="form" v-bind:item="saveDataFormat"></slot>
        <v-spacer></v-spacer>
      </v-card-actions>
      <v-card-text style="height: 80vh">
        <v-container>
          <div v-if="editDataValue != null">
            <div
              v-for="(header, index) in formData"
              :key="index"
              v-show="!header.disabled && header.hiddenForm === false"
            >
              <app-form-widget-item
                :attribute-filter-object="attributeFilterObject[header.key]"
                v-model="editDataValue[header.key]"
                :edited-item="formData"
                :header="header"
                :index="index"
              ></app-form-widget-item>
            </div>
          </div>
        </v-container>
      </v-card-text>
      <v-overlay :value="loading">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </v-card>
  </v-dialog>
</template>
<script>
import Swal from 'sweetalert2'
import { Agh } from '@/helper/app'
import AppFormWidgetItem from '@/views/widget/AppFormWidgetItem'
import { apiDirectory } from '@/api/api-directory'

export default {
  name: 'AppFormWidget',
  components: { AppFormWidgetItem },
  data() {
    return {
      attributeFilterObject: [],
      editedIndex: -1,
      apiDirectory: null,
      editedItem: {},
      name: '',
      mutationData: [],
      editDataValue: null,
      content: {
        dialog: false,
        model: '',
        dataRequest: '',
        dataValue: null
      },
      Int32: (v) => {
        if (!isNaN(parseFloat(v)) && v >= 0 && v <= 2147483647) return true
        return 'Число должно быть от 0 до 2 147 483 647.'
      },
      Decimal: (v) => {
        if (!isNaN(parseFloat(v))) return true
        return 'Число должно быть от Decimal'
      },
      Double: (v) => {
        if (!isNaN(parseFloat(v))) return true
        return 'Число должно быть от Double'
      }
    }
  },
  props: {
    edit: {
      type: Boolean,
      delete: false
    },
    value: {
      type: Object
    },
    modelValue: {
      type: Object,
      default: null
    },
    propsMutationData: {
      type: Array
    },
    loading: {
      type: Boolean,
    }
  },
  created() {
    this.apiDirectory = new apiDirectory('', '')
    if (typeof this.propsMutationData == 'object')
      this.mutationData = this.propsMutationData
  },
  computed: {
    formData() {
      let formDataModel = []
      if (this.modelValue) {
        for (const [keyItem, value] of Object.entries(this.modelValue)) {
          let options = [],
            disabled = false,
            isEnum = false,
            type = 'text',
            dataValue = '',
            textType = [],
            hiddenTable = false,
            hiddenForm = false

          const key = value.entityName
          const isNullable = !!value.attributes.Nullable
          if (
            value.attributes.ReadOnlyAttribute == 'True' ||
            value.attributes.EditableAttribute == 'False'
          )
            disabled = true
          if (value.isEnum) {
            isEnum = true
            dataValue = value.attributes.Values
            type = 'enum'
            options = this.setOptionSelect(dataValue)
          }
          if (key == 'Id' || key == 'CreatedBy' || key == 'Created')
            disabled = true

          if (value.attributes.MultilineAttribute == 'True') type = 'textarea'
          let modelTable = ''
          let modelName = ''
          if (value.attributes.DataSourceAttribute != null) {
            const DataSourceArray =
              value.attributes.DataSourceAttribute.split('|')
            modelTable = DataSourceArray[1].slice(4)
            modelName = DataSourceArray[2]
            type = 'select'
          }
          if (value.entityType === 'DateTime') {
            type = 'dateTime'
          }
          if (value.attributes.DependsOnAttribute != null) {
            type = 'parentSelect'
          }
          if (value.entityType === 'Int32') {
            textType = [this.Int32]
          }
          if (value.entityType === 'Decimal') {
            textType = [this.Decimal]
          }
          if (value.entityType === 'Double') {
            textType = [this.Double]
          }
          if (value.attributes.HiddenAttribute) {
            const HiddenAttribute = value.attributes.HiddenAttribute.split('|')
            hiddenTable = HiddenAttribute[0].toLowerCase() === 'true'
            hiddenForm = HiddenAttribute[1].toLowerCase() === 'true'
          }
          formDataModel.push({
            text: value.name,
            key: key,
            disabled: disabled,
            type: type,
            options: options,
            textType: textType,
            numeral: value.entityType.match(/Int32|Decimal|Double/),
            modelTable: modelTable,
            modelName: modelName,
            sheet: false,
            dataValue: dataValue,
            isEnum: isEnum,
            hiddenForm: hiddenForm,
            prefix: null,
            postfix: null,
            attributes: value.attributes,
            defaultValue: value.attributes.DefaultValueAttribute,
            isNullable
          })
        }
      }
      if (this.mutationData.length > 0) {
        this.mutationData.forEach((mutationItem) => {
          const mutationItemData = mutationItem(formDataModel)
          if (mutationItemData != null) formDataModel = mutationItemData
        })
      }
      return formDataModel
    },
    formTitle() {
      return this.editedIndex === -1
        ? `Добавить ${this.name}`
        : `Изменить ${this.name}`
    },
    saveDataFormat() {
      const myObject = {}
      if (this.editDataValue === null) return myObject
      if (typeof this.editDataValue === 'object') {
        Object.keys(this.editDataValue).forEach((key) => {
          const formDataItem = this.formData.find((v) => v.key === key)
          if (formDataItem != null) {
            if (formDataItem.disabled === false || formDataItem.key === 'Id')
              myObject[key] = this.getValueByType(this.editDataValue[key])
          }
        })
        return myObject
      }
      return myObject
    }
  },
  methods: {
    setDefaultValue(key, value, type) {
      if (this.edit) return
      if (this.content.dataValue == null) this.content.dataValue = {}
      if (type === 'select') {
        const valueSelect = value.split('|')
        this.content.dataValue[`${key.slice(0, -2)}Name`] = valueSelect[1]
        this.content.dataValue[key] = valueSelect[0]
      } else {
        this.content.dataValue[key] = value
      }
    },
    setOptionSelect(dataValue) {
      const options = []
      for (const [key, value] of Object.entries(
        this.parseStringToArray(dataValue)
      )) {
        options.push({
          text: value,
          value: parseInt(key)
        })
      }
      return options
    },
    alertE(text = '') {
      Swal.fire('Ошибка', text, 'error')
    },
    /**
     * Функция для получения значений в форму есть обработка в зависимости от типа значения
     * @param dataValue Массив входных значений
     * @param valueName Имя поля для которого нужно получить значения из массива значений
     * @param type Тип поля обрабатываемого входного значения
     * @return {string}
     */
    getValueDadaForm(dataValue, valueName, type) {
      let value = ''
      if (dataValue != null) {
        if (type === 'select') {
          let key = 0,
            valueText = 'Выбор'
          const q = `${valueName.slice(0, -2)}Name`
          const filterIndex = Agh.getIndex(dataValue, q)
          if (filterIndex === -1) {
            this.alertE(`Не удалось найти значения ${q}`)
            valueText = `Ошибка!!! Не удалось найти ${q}`
          } else {
            key = dataValue[Agh.getIndex(dataValue, valueName)]
            valueText = dataValue[filterIndex]
          }
          value = {
            key: key,
            value: valueText
          }
        } else {
          value = dataValue[Agh.getIndex(dataValue, valueName)]
        }
      }
      return value
    },
    selectEvent(value, header) {
      header.sheet = false
      if (header.options.filter((value) => value.id === value.id) > 0) {
        header.value = {
          key: value.id,
          value: value.name
        }
      } else {
        header.value = {
          key: value.id,
          value: value.name
        }
      }
    },
    parseStringToArray(value) {
      const newObject = {}
      const arrayValue = value.split(';')
      arrayValue.forEach((item) => {
        const objectItem = item.split('=')
        newObject[objectItem[0]] = objectItem[1]
      })
      return newObject
    },
    getValueByType(item) {
      if (typeof item === 'object' && item != null) {
        return item.key
      }
      return item
    },
    save() {
      this.$emit('save', this.saveDataFormat)
    },
    close() {
      this.content.dialog = false
    },
    setValueData() {
      const editDataValue = {}
      for (let i = 0; i < this.formData.length; i++) {
        let valueData = ''
        const formDataItem = this.formData[i]
        const key = Agh.firstCharLowCase(formDataItem.key)
        if (this.content != null) {
          if (formDataItem.defaultValue) {
            this.setDefaultValue(
              key,
              formDataItem.defaultValue,
              formDataItem.type
            )
          }
          if (this.content.dataValue !== null) {
            if (this.content.dataValue[key] !== undefined) {
              valueData = this.getValueDadaForm(
                this.content.dataValue,
                formDataItem.key,
                formDataItem.type
              )
            }
          }
          editDataValue[formDataItem.key] = valueData
        }
      }
      this.editDataValue = editDataValue
    },
    setEditedItemWatch(val) {
      for (const [key, value] of Object.entries(val)) {
        const checkValueList = this.formData.find((item) => item.key === key)
        if (checkValueList.type === 'parentSelect') {
          const attribute =
              checkValueList.attributes.DependsOnAttribute.split('|'),
            selectValue = attribute[1]
          this.attributeFilterObject[key] = { select: [], disabled: true }
          const filterVal = val[selectValue]
          if (filterVal === undefined) {
            Swal.fire(
              'Ошибка поиска имени переменной для списка',
              `Не удалось найти значения *2 ${selectValue}`,
              'error'
            )
          }
          if (filterVal !== '' && filterVal !== null) {
            this.getDataSelectParent(key, filterVal.key, attribute[0])
          }
        }
      }
    },
    /**
     * конфигурация форма от входных данных
     */
    watchParentData(val) {
      if (this.modelValue) {
        this.setValueData()
      }
    },
    async getDataSelectParent(selectValue, value, ComboValues) {
      const val = await this.apiDirectory.getGostsCombo(ComboValues, value)
      const options = []

      val.data.forEach((valueFor) => {
        options.push({
          text: valueFor.name,
          value: valueFor.id
        })
      })
      this.attributeFilterObject = {}
      this.attributeFilterObject[selectValue] = {
        select: options,
        disabled: false
      }
    }
  },
  watch: {
    editDataValue: {
      handler: function (val, oldVal) {
        this.setEditedItemWatch(val)
      },
      deep: true
    },
    content: {
      handler: function (val, oldVal) {
        this.watchParentData(val)
        this.$emit('input', val)
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style scoped></style>
