<script>
import dayjs from 'dayjs'
import ContentPlaceholder from '@/components/ContentPlaceholder'

export default {
  components: {
    ContentPlaceholder
  },
  props: {
    items: {
      type: Array,
      required: true
    },
    icon: String
  },
  methods: {
    displayDate(date) {
      return dayjs(date).format('ddd DD.MM.YY')
    }
  }
}
</script>

<template>
  <v-list>
    <v-list-item v-for="(item, i) of items" :key="i">
      <v-list-item-avatar>
        <v-icon class="blue lighten-1" dark>
          {{ icon || 'mdi-file-image' }}
        </v-icon>
      </v-list-item-avatar>

      <v-list-item-content>
        <v-list-item-title v-text="item.name"></v-list-item-title>

        <v-list-item-subtitle
          v-text="displayDate(item.date)"
        ></v-list-item-subtitle>
      </v-list-item-content>

      <v-list-item-action>
        <slot name="action" :item="item" />
      </v-list-item-action>
    </v-list-item>

    <content-placeholder
      key="placeholder"
      v-if="!items.length"
      icon="mdi-file-image"
      text="Файлов нет"
    />
  </v-list>
</template>