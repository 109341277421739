<script>
import dayjs from 'dayjs'
import ContentPlaceholder from '@/components/ContentPlaceholder'
import { getPhotoPreview } from '@/modules/PrePhoto/service'

export default {
  components: {
    ContentPlaceholder
  },
  props: {
    items: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      currentPage: 1,
      itemsPerPage: 6,
      images: [],
      actions: [{ title: 'Удалить', name: 'delete', icon: 'mdi-delete' }]
    }
  },
  computed: {
    pageItems() {
      return this.items.slice(
        (this.currentPage - 1) * this.itemsPerPage,
        this.currentPage * this.itemsPerPage
      )
    },
    totalPages() {
      return Math.ceil(this.items.length / this.itemsPerPage)
    },
    isPaginationVisible() {
      return this.totalPages > 1
    }
  },
  watch: {
    currentPage: {
      handler() {
        this.getPreviews()
      },
      immediate: true
    },
    items() {
      this.getPreviews()
    }
  },
  methods: {
    displayDate(date) {
      return dayjs(date).format('ddd DD.MM.YY')
    },
    async getImagePreview(item) {
      if (item.src || item.loading) return
      item.loading = true
      const { data } = await getPhotoPreview(item.fileId)
      item.loading = false
      const { fileContents, contentType } = data
      const result = {
        ...item,
        src: `data:${contentType};base64,${fileContents}`
      }
      this.items.splice(this.items.indexOf(item), 1, result)
    },
    async getPreviews() {
      for (let i = 0; i < this.pageItems.length; i++) {
        this.getImagePreview(this.pageItems[i])
      }
    },
    openImage(item) {
      if (!item.src) return
      this.$root.$emit('open-fullscreen-image', {
        src: item.src,
        meta: this.getImageMeta(item)
      })
    },
    getImageMeta(item) {
      if (!item) return []
      return [
        { name: 'Имя файла', value: item.filename },
        { name: 'Дата', value: this.displayDate(item.date) }
      ]
    }
  }
}
</script>

<template>
  <div class="gallery">
    <v-row v-if="pageItems.length">
      <v-col
        v-for="(item, i) in pageItems"
        :key="item.id + i"
        class="d-flex child-flex pa-0"
        cols="6"
      >
        <div class="gallery__item" @click="openImage(item)">
          <div class="gallery__item-actions">
            <v-menu bottom left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  dark
                  icon
                  v-bind="attrs"
                  v-on="on"
                  class="gallery__item-actions-btn"
                >
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>

              <v-list class="pa-0">
                <v-list-item
                  class="pa-0"
                  v-for="(action, k) in actions"
                  :key="k"
                >
                  <v-list-item-title>
                    <v-btn @click="$emit(action.name, item)">
                      <v-icon color="grey lighten-1">{{ action.icon }}</v-icon>
                      {{ action.title }}
                    </v-btn>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>

          <v-img :src="item.src" aspect-ratio="1" class="grey lighten-2">
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular
                  indeterminate
                  color="grey lighten-5"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </div>
      </v-col>
    </v-row>
    <content-placeholder v-else icon="mdi-file-image" text="Фотографий нет" />
    <v-pagination
      v-if="isPaginationVisible"
      v-model="currentPage"
      :length="totalPages"
      circle
    ></v-pagination>
  </div>
</template>

<style lang="scss" scoped>
.gallery {
  display: flex;
  flex-direction: column;
  gap: 20px;

  &__item {
    position: relative;
    cursor: pointer;
    &:hover::before {
      content: '';
      display: block;
      position: absolute;
      inset: 0;
      z-index: 1;
      width: 100%;
      height: 100%;
      background-color: #00000044;
    }
    &-actions {
      position: absolute;
      inset: 0;
      display: flex;
      justify-content: flex-end;
      padding: 0.5rem;
      z-index: 2;
    }
  }
}
</style>
