import http from './http-common'
import store from '../store'
import GeneralAPI from '.'

/**
 * @param {apiDocument} apiDocument
 */
export class apiDocument extends GeneralAPI {
  _basicAuth = ''
  constructor() {
    super()
    this._basicAuth = `Bearer ${store.getters.token}`
  }

  /**
   * Получить печатную форму
   */
  documentType(id, type) {
    return http
      .get(`/Document/${id}/${type}`, {
        headers: {
          Authorization: this._basicAuth
        },
        responseType: 'blob'
        // responseType: 'arraybuffer' // Можно и так для преобразования в
      })
      .catch((error) => {
        this.errorCatch(error)
        return Promise.reject(error)
      })
  }
  /**
   * Проверка корректности приложенных к заявке данных
   * @param id id заявки
   */
  validate(id) {
    return http
      .get(`/Claim/${id}/check`, {
        headers: {
          Authorization: this._basicAuth
        }
      })
      .catch((error) => {
        this.errorCatch(error)
        return Promise.reject(error)
      })
  }
  /**
   * Показать файл
   */
  documentTypeOpen(id, type) {
    return http
      .get(`/Document/${id}/${type}/pdf`, {
        headers: {
          Authorization: this._basicAuth
        },
        responseType: 'blob'
        // responseType: 'arraybuffer' // Можно и так для преобразования в
      })
      .catch((error) => {
        this.errorCatch(error)
        return Promise.reject(error)
      })
  }
  /**
   * Получить все файлы
   */
  documentTypeMany(id) {
    return http
      .get(`/Document/many/${id}`, {
        headers: {
          Authorization: this._basicAuth
        },
        responseType: 'blob'
        // responseType: 'arraybuffer' // Можно и так для преобразования в
      })
      .catch((error) => {
        this.errorCatch(error)
        return Promise.reject(error)
      })
  }
  /**
   * Скачать файлы по типу
   */
  downloadDocumentsByType(ids, documentType) {
    return http
      .get(`/Document/many/${ids.join(',')}/${documentType}`, {
        headers: {
          Authorization: this._basicAuth
        },
        responseType: 'blob'
        // responseType: 'arraybuffer' // Можно и так для преобразования в
      })
      .catch((error) => {
        this.errorCatch(error)
        return Promise.reject(error)
      })
  }
  /**
   * Получить все типы документа
   */
  getDocumentTypes() {
    return http
      .get(`/utils/BaseFile/Claim`, {
        headers: {
          Authorization: this._basicAuth
        }
      })
      .catch((error) => {
        this.errorCatch(error)
        return Promise.reject(error)
      })
  }
  /**
   * Получить пакет документов
   */
  document(id) {
    return http.get(`/Document/${id}`, {
      headers: {
        Authorization: this._basicAuth
      },
      responseType: 'blob'
    })
  }
  /**
   * Получить 1 файл документа
   */
  UploadFile(id) {
    return http
      .get(`/UploadFile/${id}`, {
        headers: {
          Authorization: this._basicAuth
        }
      })
      .catch((error) => {
        this.errorCatch(error)
        return Promise.reject(error)
      })
  }
  /**
   * Главное меню
   */
  mainMenu() {
    return http
      .get('/utils/MainMenu', {
        headers: {
          Authorization: this._basicAuth
        },
        responseType: 'blob'
      })
      .catch((error) => {
        this.errorCatch(error)
        return Promise.reject(error)
      })
  }
}
