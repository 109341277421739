<script>
import dayjs from 'dayjs'
import axios from 'axios'
import ContentPlaceholder from '@/components/ContentPlaceholder'

function dataURLtoFile(dataurl, filename) {
  var arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n)

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }

  return new File([u8arr], filename, { type: mime })
}

export default {
  components: {
    ContentPlaceholder
  },
  data() {
    return {
      stream: null,
      capturedData: null,
      isBlocked: false,
      frontCamera: Boolean(localStorage.getItem('front-camera')),
      errorMessage: ''
    }
  },
  computed: {
    showFacingModeSwitch() {
      return this.stream && !this.capturedData
    },
    settings() {
      return this.$store.getters.camera
    }
  },
  watch: {
    frontCamera(value) {
      this.getStream()
      if (value) localStorage.setItem('front-camera', true)
      else localStorage.removeItem('front-camera')
    }
  },
  methods: {
    async init() {
      this.capturedData = null
      await this.getStream()
    },
    stop() {
      this.stream?.getTracks().forEach((track) => track.stop())
    },
    async getStream() {
      if (!navigator.mediaDevices) {
        this.errorMessage = 'mediaDevices is not supported.'
        return
      }

      this.stop()

      try {
        const constraints = {
          audio: false,
          video: {
            facingMode: { exact: this.frontCamera ? 'user' : 'environment' }
          }
        }

        if (this.settings) {
          constraints.video.width = { ideal: this.settings.width }
          constraints.video.height = { ideal: this.settings.height }
        }

        this.stream = await navigator.mediaDevices.getUserMedia(constraints)
        if (this.stream) {
          this.$refs.video.srcObject = this.stream
          this.$emit('opened', this.stream)
        } else {
          this.isBlocked = true
        }

        if (this.isBlocked) this.$emit('blocked')
      } catch (err) {
        this.errorMessage = err.message
      }
    },
    async takePhoto() {
      const video = this.$refs.video
      const canvas = document.createElement('canvas')
      canvas.setAttribute('width', video.videoWidth)
      canvas.setAttribute('height', video.videoHeight)

      canvas
        .getContext('2d')
        .drawImage(video, 0, 0, video.videoWidth, video.videoHeight)

      this.stop()

      this.capturedData = canvas.toDataURL(
        'image/jpeg',
        (this.settings?.quality || 70) / 100
      )
      this.stream = null
    },
    submit() {
      this.$emit(
        'submit',
        dataURLtoFile(
          this.capturedData,
          `Photo-${dayjs().format('DD.MM.YY-HH:mm:ss')}.png`
        )
      )
    },
    close() {
      this.stop()
      this.$refs.video.srcObject = null
      this.stream = null
      this.capturedData = null
      this.$emit('closed')
    }
  },
  mounted() {
    this.init()
  },
  beforeDestroy() {
    this.close()
  }
}
</script>

<template>
  <v-card class="camera-card pa-0" elevation="0">
    <v-btn
      icon
      v-show="stream"
      @click="frontCamera = !frontCamera"
      class="camera-switch-btn"
      outlined
      x-large
      dark
    >
      <v-icon>mdi-camera-switch</v-icon>
    </v-btn>

    <v-btn
      icon
      v-show="stream"
      @click="close"
      class="camera-close-btn"
      outlined
      x-large
      dark
    >
      <v-icon>mdi-close</v-icon>
    </v-btn>

    {{ errorMessage }}

    <v-card v-show="stream" elevation="0" @click="takePhoto">
      <h3 class="camera-card__hint">
        Нажмите в любом месте, чтобы сделать снимок
      </h3>

      <video ref="video" autoplay playsinline></video>

      <div class="video-bg"></div>
    </v-card>

    <v-img
      v-if="capturedData"
      :src="capturedData"
      max-height="50vh"
      contain
    ></v-img>

    <content-placeholder
      v-show="!stream && !capturedData"
      icon="mdi-camera"
      text="Камера открывается..."
    />

    <v-card-actions>
      <v-btn color="blue" @click="close" text> ЗАКРЫТЬ </v-btn>
      <v-spacer></v-spacer>
      <v-btn color="blue" min-width="80" @click="init" text v-if="capturedData">
        ПЕРЕСНЯТЬ
      </v-btn>
      <v-btn
        color="blue"
        min-width="80"
        @click="submit"
        :dark="Boolean(capturedData)"
        :disabled="!capturedData"
      >
        ОК
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<style lang="scss" scoped>
%on-top {
  position: fixed;
  inset: 0;
}

%fullscreen {
  width: 100vw;
  height: 100vh;
}

.camera-card {
  width: 100%;
  video {
    @extend %fullscreen;
    @extend %on-top;
    z-index: 9;
  }
  .video-bg {
    @extend %fullscreen;
    @extend %on-top;
    z-index: 8;
    background: #000;
  }
  &__hint {
    @extend %on-top;
    width: 100%;
    color: grey;
    z-index: 10;
    opacity: 0.5;
    user-select: none;
    top: 4rem;
    left: 50%;
    font-size: 1.2rem;
    transform: translateX(-50%);
    text-align: center;
  }
  .camera-switch-btn,
  .camera-close-btn {
    position: fixed;
    z-index: 100 !important;
    bottom: 2rem;
    right: 2rem;
  }
  .camera-close-btn {
    bottom: 2rem;
    left: 2rem;
  }
}
</style>
