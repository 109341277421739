<script>
import ContentPlaceholder from '@/components/ContentPlaceholder'

export default {
  components: {
    ContentPlaceholder
  },
  data: () => ({
    isReadyToDrop: false
  }),
  methods: {
    async openFile() {
      const files = await new Promise((resolve) => {
        const input = document.createElement('input')
        input.type = 'file'
        input.accept = 'image/*'
        input.multiple = 'true'
        input.click()
        input.onchange = (e) => {
          resolve(e.target.files)
        }
      })
      if (files?.length) this.$emit('input', Array.from(files))
    },
    drop(e) {
      this.isReadyToDrop = false
      const files = Array.from(e.dataTransfer.files)
      if (files?.length) this.$emit('input', Array.from(files))
    }
  }
}
</script>

<template>
  <v-card
    class="photo-input"
    :class="{ ready: isReadyToDrop }"
    elevation="0"
    @dragover.prevent
    @dragleave="isReadyToDrop = false"
    @dragover="isReadyToDrop = true"
    @dragenter="isReadyToDrop = true"
    @drop.prevent="drop"
  >
    <div :style="{ 'pointer-events': isReadyToDrop ? 'none' : 'all' }">
        <content-placeholder icon="mdi-file-image" text="Перетащите фото сюда" />
    </div>
    <h4 v-if="!isReadyToDrop" class="photo-input__or">ИЛИ</h4>
    <v-col v-if="!isReadyToDrop" class="photo-input__actions">
      <v-btn
        @click="openFile"
        color="primary"
        height="35"
        small
        outlined
        class="photo-input__action-button"
      >
        <v-icon>mdi-laptop-chromebook</v-icon>
        <div>Открыть</div>
      </v-btn>
      <v-btn @click="$emit('take-photo')" color="primary" height="35" small outlined class="photo-input__action-button">
        <v-icon>mdi-camera</v-icon>
        <div>Сделать снимок</div>
      </v-btn>
    </v-col>
  </v-card>
</template>

<style lang="scss" scoped>
%container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  gap: 8px;
}

.photo-input {
  @extend %container;
  flex-direction: column;
  min-height: 200px;
  gap: 8px;

  &.ready {
    outline: 3px lightgray dashed;
    background: #00000011;
  }

  &__or {
    opacity: 0.5;
    font-size: 14px;
  }

  &__title {
    font-size: 20px;
    font-weight: 600;
    opacity: 0.5;
  }

  &__actions {
    @extend %container;
  }

  &__action-button div {
    margin-left: 8px;
  }
}
</style>