<script>
import FileList from '@/components/FileList.vue'
import CameraCard from '@/components/CameraCard.vue'
import Gallery from '@/components/Gallery.vue'
import ContentPlaceholder from '@/components/ContentPlaceholder.vue'
import PhotoInput from './PhotoInput.vue'
import {
  uploadPrePhoto,
  deletePrePhoto,
  getPrePhotoList,
  downloadAllPrePhotos
} from '../service'

// TODO: move these two functions to DownloadMixin or refactor this part out in some other way
function downloadBlob(filename, blob) {
  const url = window.URL.createObjectURL(blob)
  const a = document.createElement('a')
  a.href = url
  a.setAttribute('download', filename)
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
}

function parseStringToArray(value) {
  const newObject = {}
  const arrayValue = value.split(';')
  arrayValue.forEach((item) => {
    const objectItem = item.split('=')
    newObject[objectItem[0].trim()] = objectItem[1]
  })
  return newObject
}

export default {
  components: {
    PhotoInput,
    FileList,
    CameraCard,
    Gallery,
    ContentPlaceholder
  },
  data: () => ({
    preVehicleId: null,
    preVehicleVIN: "",
    photos: [],
    files: [],
    tab: null,
    tabs: ['Список файлов', 'Загрузить'],
    dialog: false,
    deleteDialog: false,
    photoToDelete: null,
    loading: true,
    downloading: false,
    isCameraOpened: false
  }),
  watch: {
    tab(value) {
      if (value == 0) {
        this.closeCamera()
        if (!this.photos.length) this.getPhotos()
      }
    },
    dialog(value) {
      if (!value) {
        setTimeout(() => {
          this.closeCamera()
          this.resetState()
        }, 300)
      }
    }
  },
  computed: {
    dialogMaxWidth() {
      return this.isCameraOpened && this.tab !== 0 ? 800 : 400
    }
  },
  methods: {
    async show(id, vin) {
      this.preVehicleId = id
      this.preVehicleVIN = vin
      this.dialog = true
      await this.getPhotos()
    },
    showCamera(id, vin) {
      this.preVehicleId = id
      this.preVehicleVIN = vin
      this.dialog = true
      this.loading = false
      this.tab = 1
      this.openCamera()
    },
    async getPhotos() {
      try {
        this.loading = true
        const { data } = await getPrePhotoList(this.preVehicleId)
        this.photos = data.data.map((photo) => ({
          ...photo,
          name: photo.filename,
          date: photo.created
        }))
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err)
      } finally {
        this.loading = false
      }
    },
    async downloadAllPhotos() {
      try {
        this.downloading = true
        const data = await downloadAllPrePhotos(this.preVehicleId)
        const filename = parseStringToArray(
          data.headers['content-disposition']
        )['filename']
        downloadBlob(filename, new Blob([data.data]))
        this.$root.$emit('snackbar', 'Фотографии загружены')
      } finally {
        this.downloading = false
      }
    },
    handleFileInput(files) {
      this.files = files
    },
    takePhoto() {
      this.$refs.camera?.takePhoto()
    },
    submitPhoto(file) {
      this.closeCamera()
      this.files.push(file)
      this.uploadPhotos()
    },
    removeUpload(file) {
      this.files = this.files.filter((item) => item != file)
    },
    async deletePhoto(photo, confirm) {
      if (!confirm) {
        this.deleteDialog = true
        this.photoToDelete = photo
      } else {
        this.deleteDialog = false
        this.photos = this.photos.filter((item) => item !== photo)
        await deletePrePhoto(photo.id)
      }
    },
    async uploadPhotos() {
      try {
        this.loading = true
        const promises = this.files.map(async (file) => {
          await uploadPrePhoto(file, this.preVehicleId)
          this.files = this.files.filter((item) => item !== file)
        })
        await Promise.all(promises)
        this.$root.$emit(
          'snackbar',
          this.files.length > 1
            ? 'Фотографии сохранены'
            : 'Фотография сохранена'
        )
        await this.getPhotos()
      } catch {
        this.loading = false
      }
    },
    clearFiles() {
      this.files = []
    },
    resetState() {
      this.photos = []
      this.loading = true
      this.tab = 0
      this.files = []
      this.preVehicleVIN = ""
    },
    openCamera() {
      this.isCameraOpened = true
    },
    closeCamera() {
      this.$refs.camera?.stop()
      this.isCameraOpened = false
    }
  }
}
</script>

<template>
  <v-dialog v-model="dialog" :max-width="dialogMaxWidth" scrollable>
    <v-card :loading="loading">
      <v-card-title>
        <div>Фотографии</div>
        <v-spacer></v-spacer>
        <v-btn
          class="px-2 mr-4"
          rounded
          elevation="0"
          color="blue"
          outlined
          :disabled="!photos.length"
          :loading="downloading"
          @click="downloadAllPhotos"
        >
          <b>{{ photos.length }}</b>
          <v-icon>mdi-download-outline</v-icon>
        </v-btn>
        <v-btn @click="dialog = false" icon>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-subtitle>
        <b>VIN:</b> {{ preVehicleVIN }}
      </v-card-subtitle>

      <v-card-text>
        <v-tabs v-model="tab">
          <v-tab v-for="item in tabs" :key="item">{{ item }}</v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item>
            <v-container fluid>
              <v-expand-transition>
                <v-col v-if="loading && !photos.length">
                  <content-placeholder loading text="Загрузка файлов" />
                </v-col>

                <gallery v-else :items="photos" @delete="deletePhoto" />
              </v-expand-transition>
            </v-container>
          </v-tab-item>

          <v-tab-item>
            <v-container fluid>
              <template v-if="files.length">
                <file-list :items="files" @delete="removeUpload">
                  <template #action="{ item }">
                    <v-btn @click="removeUpload(item)" icon>
                      <v-icon color="grey lighten-1">mdi-close</v-icon>
                    </v-btn>
                  </template>
                </file-list>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="blue"
                    @click="clearFiles"
                    text
                    :disabled="!files.length"
                    v-if="!loading && files.length"
                  >
                    ОЧИСТИТЬ
                  </v-btn>
                  <v-btn
                    color="blue"
                    min-width="80"
                    @click="uploadPhotos"
                    :loading="loading"
                    :disabled="loading || !files.length"
                    :dark="Boolean(!loading && files.length)"
                  >
                    ОК
                  </v-btn>
                </v-card-actions>
              </template>

              <photo-input
                v-else-if="!isCameraOpened"
                @take-photo="openCamera"
                @input="handleFileInput"
              />

              <template v-else>
                <camera-card
                  ref="camera"
                  @submit="submitPhoto"
                  @closed="closeCamera"
                />
              </template>
            </v-container>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
    </v-card>

    <v-dialog v-model="deleteDialog" max-width="500px">
      <v-card>
        <v-card-title>
          <span>Удалить файл?</span>
          <v-spacer></v-spacer>
          <v-btn @click="deleteDialog = false" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-container>
          <file-list :items="[photoToDelete]" />
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="deleteDialog = false" text> ОТМЕНА </v-btn>
          <v-btn @click="deletePhoto(photoToDelete, true)" color="error">
            УДАЛИТЬ
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>
