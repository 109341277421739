import axios from 'axios'
import store from '@/store'
import { errorHandler } from '@/api'

const Axios = axios.create({
  baseURL:
    process.env.NODE_ENV === 'production'
      ? location.origin + '/api'
      : 'http://localhost:8080/api',
  headers: {
    'Content-type': 'application/json'
  }
})

Axios.interceptors.request.use(function (config) {
  const token = store.getters.token
  config.headers.Authorization = `Bearer ${token}`
  return config
})

Axios.interceptors.response.use(
  (res) => {
    return res
  },
  (err) => {
    errorHandler(err)
    return Promise.reject(err)
  }
)

export const uploadPrePhoto = async (file, PreVehicleId) => {
  const formData = new FormData()

  formData.append('File', file)
  formData.append('FileType', 98)
  formData.append('FileName', file.name)

  const { data: FileId } = await Axios.post('/Uploadfile', formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })

  return attachPrePhotoToPreVehicle(FileId, PreVehicleId)
}

export const deletePrePhoto = (id) => Axios.delete('/PrePhoto/' + id)

export const getPrePhotoList = (PreVehicleId) => {
  return Axios.post('/PrePhoto/list', {
    filter: [
      {
        field: 'PreVehicleId',
        operator: 0,
        value: PreVehicleId
      }
    ],
    page: 1,
    rows: 1000
  })
}

export const downloadAllPrePhotos = (PreVehicleId) => {
  return Axios.get(`/PreVehicle/${PreVehicleId}/photos`, {
    responseType: 'blob'
  })
}

export const attachPrePhotoToPreVehicle = (FileId, PreVehicleId) => {
  return Axios.post('/PrePhoto', {
    FileId,
    PreVehicleId
  })
}

export const getPhotoPreview = (fileId, cancelToken) => {
  return Axios.get(`/UploadFile/${fileId}/thumb`, {
    cancelToken
  })
}
