<template>
  <v-tooltip top open-delay="500" :disabled="!$isMobile">
    <template v-slot:activator="{ on, attrs }">
      <v-btn x-small fab color="indigo" @click="downloadFile" dark :loading="load" v-bind="attrs" v-on="on">
        <v-icon color="white">mdi-file-download-outline</v-icon>
      </v-btn>
    </template>
    <span>Скачать документ</span>
  </v-tooltip>
</template>

<script>
import Swal from 'sweetalert2'

export default {
  name: 'btnDownload',
  data() {
    return {
      load: false
    }
  },
  props: ['item', 'event'],
  methods: {
    async downloadFile() {
      if (this.event == null) Swal.fire('Ошибка интерфейса', 'Не переданн event', 'error')
      if (this.item != null) {
        this.load = true
        try {
          await this.event(this.item)
        } catch {}
      }
      this.load = false
    }
  }
}
</script>

<style scoped lang="scss">
.loader {
  margin: 0 5px;
}
</style>
